import React from 'react';
import styled from 'styled-components';

const Checkbox = ({ checked, onChange, quizVariant, props }) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} onChange={onChange} {...props} />
    {quizVariant ? (
      <StyledCheckboxQuiz checked={checked}>
        <IconQuiz checked={checked} viewBox="0 0 24 24">
          <path
            d="M18.2511 1.64942C18.3628 1.76089 18.4515 1.89331 18.512 2.0391C18.5725 2.18489 18.6036 2.34118 18.6036 2.49902C18.6036 2.65686 18.5725 2.81315 18.512 2.95894C18.4515 3.10473 18.3628 3.23715 18.2511 3.34862L7.45109 14.1486C7.33962 14.2604 7.2072 14.349 7.06141 14.4095C6.91562 14.47 6.75933 14.5012 6.60149 14.5012C6.44365 14.5012 6.28736 14.47 6.14157 14.4095C5.99578 14.349 5.86336 14.2604 5.75189 14.1486L0.951891 9.34862C0.726563 9.12329 0.599976 8.81768 0.599976 8.49902C0.599976 8.18036 0.726563 7.87475 0.951891 7.64942C1.17722 7.42409 1.48283 7.2975 1.80149 7.2975C2.12015 7.2975 2.42576 7.42409 2.65109 7.64942L6.60149 11.6022L16.5519 1.64942C16.6634 1.53767 16.7958 1.44901 16.9416 1.38851C17.0874 1.32801 17.2437 1.29687 17.4015 1.29688C17.5593 1.29688 17.7156 1.32801 17.8614 1.38851C18.0072 1.44901 18.1396 1.53767 18.2511 1.64942Z"
            fill="#38724F"
          />
        </IconQuiz>
      </StyledCheckboxQuiz>
    ) : (
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    )}
  </CheckboxContainer>
);

export default Checkbox;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 4px;
  margin-bottom: 1px;
`;

const IconQuiz = styled.svg<{ checked: boolean }>`
  fill: none;
  stroke: ${({ theme, checked }) => (checked ? theme.colors.newGreen : '#FFF')};
  height: ${({ checked }) => (checked ? '24px' : '0px')};
  width: ${({ checked }) => (checked ? '24px' : '0px')};
  display: block;
  margin: auto;
  // stroke-width: 4px;
  position: absolute; // <-- Add this line
  top: 50%; // <-- Add this line
  left: 50%; // <-- Add this line
  transform: translate(-50%, -50%)
    scale(${props => (props.checked ? '1' : '0')}) translate(2px, 4px);
  opacity: ${props => (props.checked ? '1' : '0')}; // <-- Adjust opacity
  stroke-width: 0.5px;
  transition: transform 0.2s, opacity 0.1s;
`;

const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: ${({ checked }) => (checked ? '#2FBE8F' : '#FFF')};
  border: 1px solid ${({ checked }) => (checked ? '#2FBE8F' : '#00000026')};
  border-radius: 4px;
  transition: all 150ms;
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const StyledCheckboxQuiz = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${({ checked }) => (checked ? '#FFF' : '#FFF')};
  border: 2px solid ${({ checked, theme }) => (checked ? '#FFF' : '#d6d6d6')};
  border-radius: 4px;
  position: relative; // <-- Add this line
  transition: all 150ms;
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const CheckboxContainer = styled.div`
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
`;
