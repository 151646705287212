import { Text } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';

interface UpgradeDisclaimerProps {
  text?: string;
  cp: string;
}

const Container = styled.div`
  background-color: #f7f7f7;
  padding: 3.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  @media ${tablet} {
    padding: 2rem 1rem;
  }
`;

const TextStyled = styled(Text)`
  color: #4d4d4d;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.021rem;
  max-width: 48rem;
  width: 100%;
  @media ${tablet} {
    font-size: 0.625rem;
    padding-bottom: 0.75rem;
  }
`;

const Bottom = styled(Text)`
  color: #4d4d4d;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: -0.024rem;
`;

const UpgradeDisclaimer: FC<UpgradeDisclaimerProps> = ({ text, cp }) => (
  <Container>
    {text && <TextStyled>{text}</TextStyled>}
    <Bottom>
      {cp?.replace('${year}', new Date().getFullYear().toString())}
    </Bottom>
  </Container>
);

export default UpgradeDisclaimer;
