import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { floor } from 'lodash';

import { DynamicImage, Text } from 'components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';
import BookImage from './BookImage';
import DownloadIcon from '../../../../assets/images/success/download_icon.svg';

interface OrderSummaryProps {
  title: string;
  upsellsKeys: string[];
  bundlePhoto: { [key: string]: number };
}

const DownloadIconImg = styled(DownloadIcon)`
  width: 1.5rem;
  height: 1.5rem;
`;

const Container = styled.div`
  max-width: 71.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.5rem 1rem;
  gap: 4rem;
  @media ${tablet} {
    gap: 3rem;
    padding: 3rem 1rem;
  }
`;

const Heading = styled(Text)`
  @media ${tablet} {
    text-align: center;
  }
`;

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 872px;
  display: flex;
  flex-direction: column;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 1.5rem;
  width: 100%;
  padding-bottom: 2rem;
  @media ${tablet} {
    padding-bottom: 1.5rem;
  }
`;

const TotalItemContainer = styled(OrderItemContainer)`
  justify-content: end;
  gap: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.light100};

  padding-top: 2rem;
  @media ${tablet} {
    justify-content: space-between;
    padding-top: 0.5rem;
  }
`;

const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  @media ${tablet} {
    gap: 5px;
  }
`;

const UpsellDownload = styled.a`
  cursor: pointer;
  color: #804da1 !important;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 26px */
  letter-spacing: -0.48px;
  text-decoration-line: underline !important;
  align-items: center;
  display: flex;
  scroll-behavior: smooth;
  gap: 0.5rem;
  @media ${tablet} {
    font-size: 12px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 18px;
`;

const StyledImg = styled(DynamicImage)`
  width: 50px;
  @media ${tablet} {
    width: 33px;
  }
`;

const ItalicText = styled(Text)`
  font-style: italic;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
  align-items: center;
`;

const PriceWrapper = styled.div`
  display: flex;
  gap: 0.375rem;
  align-items: center;
`;

const FreeLabel = styled(Text)`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background: #38724f;
  color: #fff;
`;

const OldPrice = styled(Text)`
  color: #8f90a6;
  text-decoration: line-through;
`;

const Subtitle = styled(Text)`
  color: #3d3d3d;
`;

const ScrollToAppWrapper = styled.div`
  color: #804da1;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 26px */
  letter-spacing: -0.48px;
  text-decoration-line: underline;
  scroll-behavior: smooth;
  align-items: center;
  display: flex;
  gap: 0.5rem;
  @media ${tablet} {
    font-size: 12px;
  }
`;

const MultipleUpsellLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const OrderSummary: FC<OrderSummaryProps> = ({
  title,
  upsellsKeys,
  bundlePhoto,
}) => {
  const { isTablet } = useQuery();

  const {
    selected_plans,
    upsell_products,
    quiz_answers,
    selected_plans_options,
  } = useSelector((state: AppState) => state.user);
  const dogBreedList = useSelector((state: AppState) => state?.dogBreed?.list);
  const { coverUrl, textColor, userName, userDogName, ebookUrl, dogs_breed } =
    quiz_answers;

  const selectedProduct = getLocalisedProduct(selected_plans[0]);
  const localisedUpsellProducts = upsell_products.map(p =>
    getLocalisedProduct(p),
  );

  const currency = selectedProduct?.currencyId;

  const boughtUpsell = upsell_products.map(item => item.key);

  const sortedUpsells = upsellsKeys.filter(key => !boughtUpsell.includes(key));

  const getBundlePhoto = () => {
    let result = '';
    sortedUpsells?.forEach(str => {
      if (bundlePhoto[str] !== undefined) {
        result += bundlePhoto[str].toString();
      }
    });
    if (result === '') {
      return '1234';
    }
    return result;
  };

  const renderUpsellProducts = (selected_plan: Product, index: number) => {
    const upsellProduct = getLocalisedProduct(selected_plan);
    return (
      <OrderItemContainer key={index}>
        <Text type={isTablet ? 'body' : 'h3400'} color="dark80">
          {upsellProduct.name}
        </Text>
        <Text type={isTablet ? 'body' : 'h3400'} color="dark80">
          {upsellProduct.currency}
          {upsellProduct.finalPrice}
        </Text>
      </OrderItemContainer>
    );
  };

  const upsellsPrice = localisedUpsellProducts?.reduce((sum, p) => {
    if (p?.hasTrial) {
      return sum + parseFloat(p.discountedTrialPrice || '0');
    }

    return sum + parseFloat(p?.finalPrice || '0');
  }, 0);

  const buildValueKey = (item: string) =>
    item.toLowerCase().replace(/\s+/g, '_');

  // const productVisiblePrice = selectedProduct?.hasTrial
  //   ? selectedProduct?.discountedTrialPrice
  //   : selectedProduct?.finalPrice;

  // const totalPrice = (
  //   parseFloat(Number(productVisiblePrice)) + parseFloat(upsellsPrice)
  // ).toFixed(2);

  const localisedProducts = selected_plans?.map(product =>
    getLocalisedProduct(product),
  );
  const calculateFullPrice = products => {
    let fullPrice = 0;

    for (let i = 0; i < products.length; i++) {
      fullPrice =
        fullPrice +
        Number(
          products[i].hasTrial
            ? products[i].discountedTrialPrice
            : products[i].finalPrice,
        );
    }

    return fullPrice;
  };

  const totalPrice = calculateFullPrice(localisedProducts);

  const totalPriceUpsell = upsell_products.reduce((prevValue, product) => {
    const productPrice = product.prices.filter(
      priceItem => priceItem.currency_id == currency,
    )[0]?.final_price;
    return prevValue + productPrice;
  }, 0);

  const resolvePageTextContent = product => {
    const price = product?.prices?.filter(
      priceItem => priceItem.currency_id === currency,
    )[0]?.final_price;

    if (
      product?.is_subscription &&
      product?.is_upsell &&
      product?.key.includes('upsell-raising-dog-1-month')
    ) {
      return {
        title: product?.name,
        subtitle: (
          <ScrollToAppWrapper
            onClick={() => window.scrollTo({ top: 250, behavior: 'smooth' })}
          >
            <DownloadIconImg />
            {product?.description}
          </ScrollToAppWrapper>
        ),
        price,
        productImg: 'success/subscriptionProductImg.png',
      };
    }

    if (
      product?.is_subscription &&
      product?.is_upsell &&
      product?.key.includes('raising-dog_upsell_1-month_sub_trial')
    ) {
      return {
        title: 'Raising Dog assistant app',
        subtitle: (
          <ScrollToAppWrapper
            onClick={() => window.scrollTo({ top: 250, behavior: 'smooth' })}
          >
            <DownloadIconImg />
            Download here
          </ScrollToAppWrapper>
        ),
        oldPrice: price,
        productImg: 'success/subscriptionProductImg.png',
      };
    }

    if (product?.is_subscription && product.is_upsell) {
      return {
        title: product.name,
        price,
        hasImage: false,
        is_upsell: product?.is_upsell,
      };
    }
    if (product?.is_subscription) {
      return {
        title: 'Raising Dog assistant app',
        subtitle: (
          <ScrollToAppWrapper
            onClick={() => window.scrollTo({ top: 250, behavior: 'smooth' })}
          >
            <DownloadIconImg />
            {product?.description}
          </ScrollToAppWrapper>
        ),
        price,
        productImg: 'success/subscriptionProductImg.png',
        hasTrial: product?.has_trials,
      };
    }

    if (product?.type === 'physical' && product.is_upsell) {
      return {
        title: product.name,
        price,
        hasImage: false,
        is_upsell: product?.is_upsell,
      };
    }
    if (product.type === 'physical') {
      return {
        title: product.name,
        subtitle: product.description,
        price,
        productImg: coverUrl,
      };
    }
    if (product.key.includes('upsell-raising-dog-make-dog-famous')) {
      return {
        title: product.name,
        subtitle: (
          <UpsellDownload
            href={
              'https://drive.google.com/file/d/1xsrXrPfafoXeyRgJ9YZ9-26M97njZVnz/view?usp=drive_link'
            }
            target="_blank"
          >
            <DownloadIconImg />
            Download here
          </UpsellDownload>
        ),
        price,
        productImg: 'upsell/dog-famous/card.png',
      };
    }
    if (product.key.includes('upsell-raising-dog-essential-care')) {
      return {
        title: product.name,
        subtitle: (
          <UpsellDownload
            href={
              'https://drive.google.com/uc?export=download&id=1ccvmBM56tpFdvRKwwV4CDtfyueCzsf1m'
            }
            target="_blank"
          >
            <DownloadIconImg />
            Download here
          </UpsellDownload>
        ),
        price,
        productImg: 'upsell/essential-care/card.png',
      };
    }
    if (product.key.includes('upsell-raising-dog-home-cooking')) {
      return {
        title: product.name,
        subtitle: (
          <UpsellDownload
            href={
              'https://drive.google.com/uc?export=download&id=1MArgp7ZckyEZJsirL3oK8Wsj_t4ZWyVc'
            }
            target="_blank"
          >
            <DownloadIconImg />
            Download here
          </UpsellDownload>
        ),
        price,
        productImg: 'upsell/home-cooking/card.png',
      };
    }
    if (product.key.includes('upsell-raising-dog-advanced-tricks')) {
      return {
        title: product.name,
        subtitle: (
          <UpsellDownload
            href={
              'https://drive.google.com/uc?export=download&id=1kMW2AkqIk94Bs9dnE3_NlqUO3hgQdIy8'
            }
            target="_blank"
          >
            <DownloadIconImg />
            Download here
          </UpsellDownload>
        ),
        price,
        productImg: 'upsell/advanced-tricks/card.png',
      };
    }
    if (product.key.includes('upsell-raising-dog-parents-bundle')) {
      const planKeys = upsell_products.map(up => up.key);
      return {
        title: product.name,
        subtitle: (
          <MultipleUpsellLinkContainer>
            {planKeys.includes('upsell-raising-dog-make-dog-famous') ? null : (
              <UpsellDownload
                href={
                  'https://drive.google.com/file/d/1xsrXrPfafoXeyRgJ9YZ9-26M97njZVnz/view?usp=drive_link'
                }
                target="_blank"
              >
                <DownloadIconImg />
                Make Your Dog Famous
              </UpsellDownload>
            )}
            {planKeys.includes('upsell-raising-dog-essential-care') ? null : (
              <UpsellDownload
                href={
                  'https://drive.google.com/uc?export=download&id=1ccvmBM56tpFdvRKwwV4CDtfyueCzsf1m'
                }
                target="_blank"
              >
                <DownloadIconImg />
                Essential Dog Care Planner
              </UpsellDownload>
            )}
            {planKeys.includes('upsell-raising-dog-home-cooking') ? null : (
              <UpsellDownload
                href={
                  'https://drive.google.com/uc?export=download&id=1MArgp7ZckyEZJsirL3oK8Wsj_t4ZWyVc'
                }
                target="_blank"
              >
                <DownloadIconImg />
                Home Cooking for Your Dog
              </UpsellDownload>
            )}
            {planKeys.includes('upsell-raising-dog-advanced-tricks') ? null : (
              <UpsellDownload
                href={
                  'https://drive.google.com/uc?export=download&id=1kMW2AkqIk94Bs9dnE3_NlqUO3hgQdIy8'
                }
                target="_blank"
              >
                <DownloadIconImg />
                Advanced Tricks
              </UpsellDownload>
            )}
          </MultipleUpsellLinkContainer>
        ),
        price,
        productImg: `upsell/bundle/bundle-${getBundlePhoto()}.png`,
      };
    }
    if (
      (product.key === 'e-book-free' && product.is_upsell) ||
      (product.key.includes('upsell-e-book-express-shipping') &&
        product.is_upsell)
    ) {
      return {
        title: product.name,
        price,
        hasImage: false,
        is_upsell: product?.is_upsell,
      };
    }
    return {
      title: product.name,
      subtitle: dogBreedList.find(
        item => item.value === buildValueKey(dogs_breed).trim(),
      )?.contentReady ? (
        <UpsellDownload href={ebookUrl} target="_blank">
          <DownloadIconImg />
          Download here
        </UpsellDownload>
      ) : (
        product.description
      ),
      price,
      productImg: coverUrl,
    };
  };
  const minimizeBookTitle =
    (userName?.length || 0 + userDogName?.length || 0) > 15;

  const subscriptionsSummary = [];
  const paperBookSummary = [];
  const eBookSummary = [];
  const otherBundles = [];

  const allProducts = [...(selected_plans || []), ...(upsell_products || [])];

  allProducts?.forEach(product => {
    const hasImage = product?.is_upsell ? false : true;

    if (product.is_subscription) {
      subscriptionsSummary.push({ ...product, hasImage });
      return;
    }
    if (product.type === 'physical') {
      paperBookSummary.push({ ...product, hasImage });
      return;
    }

    if (product.key.includes('e-book')) {
      eBookSummary.push({ ...product, hasImage });
      return;
    } else {
      otherBundles.push({ ...product, hasImage: true });
      return;
    }
  });

  const groupedProductsArray = [
    ...(subscriptionsSummary || []),
    ...(paperBookSummary || []),
    ...(eBookSummary || []),
    ...(otherBundles || []),
  ];

  return (
    <Container>
      <Heading type={isTablet ? 'h2600' : 'h1'}>{title}</Heading>
      <SummaryContainer>
        {groupedProductsArray?.map(item => {
          const { title, subtitle, price, productImg, hasTrial } =
            resolvePageTextContent(item);

          const imgTextTop = isTablet
            ? minimizeBookTitle
              ? '4px'
              : '3.5px'
            : minimizeBookTitle
            ? '6px'
            : '5px';

          const imgTextFontSize = isTablet
            ? minimizeBookTitle
              ? '1px'
              : '2px'
            : minimizeBookTitle
            ? '1.5px'
            : '2px';

          return (
            <OrderItemContainer key={title}>
              {productImg ? (
                <BookImage
                  width={isTablet ? '33px' : '50px'}
                  coverUrl={productImg}
                  alt="product"
                  textColor={!item?.is_subscription && textColor}
                  userName={!item?.is_subscription && userName}
                  userDogName={!item?.is_subscription && userDogName}
                  top={!item?.is_subscription ? imgTextTop : undefined}
                  fontSize={
                    !item?.is_subscription ? imgTextFontSize : undefined
                  }
                />
              ) : null}
              {productImg ? (
                <ProductInfoContainer>
                  <PriceContainer>
                    <Text type={isTablet ? 'body600' : 'h2600'} color="dark80">
                      {title}
                    </Text>
                    <Text type={isTablet ? 'body' : 'h3400'} color="dark80">
                      {!price || hasTrial
                        ? 'FREE'
                        : selectedProduct?.currency + price.toFixed(2)}
                    </Text>
                  </PriceContainer>

                  <Subtitle type={isTablet ? 'bodyS' : 'h3400'} color="dark80">
                    {subtitle}
                  </Subtitle>
                </ProductInfoContainer>
              ) : (
                <ProductInfoContainer>
                  <PriceContainer>
                    <AddonText
                      type={isTablet ? 'body600' : 'h2600'}
                      color="dark80"
                    >
                      {title}
                    </AddonText>
                    <Text type={isTablet ? 'body' : 'h3400'} color="dark80">
                      {!price
                        ? 'FREE'
                        : selectedProduct?.currency + price.toFixed(2)}
                    </Text>
                  </PriceContainer>

                  <Subtitle type={isTablet ? 'bodyS' : 'h3400'} color="dark80">
                    {subtitle}
                  </Subtitle>
                </ProductInfoContainer>
              )}
            </OrderItemContainer>
          );
        })}
        <TotalItemContainer>
          <Text type={isTablet ? 'h4700' : 'h1S600'} color="dark80">
            Total Amount:
          </Text>
          <Text type={isTablet ? 'h4700' : 'h1S600'} color="dark80">
            {selectedProduct?.currency}
            {(
              Math.round(Number(totalPrice + upsellsPrice) * 100) / 100
            ).toFixed(2)}
          </Text>
        </TotalItemContainer>
      </SummaryContainer>
    </Container>
  );
};

export default OrderSummary;

const AddonText = styled(Text)`
  margin-left: 3rem;
  font-style: italic;
  font-weight: 400;
  font-size: 1.25rem;
  color: #555770;
  @media ${tablet} {
    font-size: 1rem;
    margin-left: 2rem;
  }
`;
