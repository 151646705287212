import React, { FC } from 'react';
import styled from 'styled-components';

import BannerOne from '../BannerOne';
import { mobile, smMobile, tablet, useQuery } from 'styles/breakpoints';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import Headline from '../Headline';
import Label from '../Label';
import Button from '../Button';
import CancelButton from '../CancelButton';
import { ImageWithContainer } from '../..';
import List from '../List';
import ImageSection from 'pages/start/checkout/components/ImageSection';
import { DynamicImage } from 'components';

import LikeIcon from 'assets/icons/like-icon.svg';
import UpgradeDisclaimer from '../UpgradeDisclaimer';

interface AppSubscriptionProps {
  commentsTitle?: string;
  commentsSubtitle?: string;
  commentsTitleHasIcon?: boolean;
  disclaimer?: {
    text?: string;
    cp: string;
  };
}

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  background-color: #f6f4ef;
  @media ${mobile} {
    padding: 1.5rem 2.125rem;
  }
  @media ${smMobile} {
    padding: 1.5rem 0.75rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  max-width: 56.25rem;
  width: 100%;
`;
const CancelButtonStyled = styled(CancelButton)`
  margin: 0.75rem 0 3rem 0;
`;

const ListStyled = styled(List)`
  /* padding-bottom: 3rem; */

  @media ${tablet} {
    /* padding-bottom: 1rem; */
  }
`;

const TopTextContainer = styled.div`
  background-color: #f6f4ef;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeadlineAlt = styled.p`
  color: #1c1c28;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 2.8rem */
  margin-bottom: 1rem;
  max-width: 48rem;

  @media ${tablet} {
    max-width: 21.4375rem;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
`;

const LabelAlt = styled.p`
  color: #28293d;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 1.625rem */
  margin-bottom: 2.25rem;
  text-align: center;
  max-width: 48rem;
  @media ${tablet} {
    max-width: 21.4375rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

const ImageSectionStyled = styled(DynamicImage)<{ width: string }>`
  width: ${({ width }) => width};
  width: 100%;
  max-width: 74.25rem;

  @media ${tablet} {
    max-width: 21.4375rem;
  }
`;

const ImgContainer = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 8rem;

  @media ${tablet} {
    padding: 2rem 1rem;
  }
`;

const CommentsTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;

  @media ${tablet} {
    gap: unset;
    margin-bottom: 1rem;
  }
`;

const CommentsTitleWrapper = styled.div`
  display: flex;
  gap: 0.286rem;
  align-items: center;
`;

const CommentsTitle = styled.p`
  color: #1c1c28;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.01925rem;

  @media ${tablet} {
    font-size: 1.5rem;
    letter-spacing: -0.0165rem;
  }
`;

const CommentsSubtitle = styled.p`
  color: #1c1c28;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.011rem;
  text-align: center;

  @media ${tablet} {
    font-size: 1rem;
  }
`;

const StyledLikeIcon = styled(LikeIcon)`
  width: 2.28781rem;
  height: 2.28781rem;
  padding: 0.19063rem 0.286rem 0.11781rem 0.2145rem;

  @media ${tablet} {
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.18744rem 0.28138rem 0.11588rem 0.21094rem;
  }
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ListWrapper = styled.div`
  max-width: 35rem;
  width: 100%;
`;

const AppSubscription: FC<AppSubscriptionProps> = ({
  bannerOne,
  paymentSteps,
  headlineAlt,
  labelAlt,
  list,
  planSection,
  PlanSectionComponent,
  headline2,
  headline3,
  label,
  planSection2,
  imageSection,
  bannerTexts,
  userHasBoughtPaperbackBook,
  commentsTitle,
  commentsSubtitle,
  commentsTitleHasIcon,
  disclaimer,
}) => {
  const { isTablet } = useQuery();
  return (
    <>
      {bannerOne && !bannerTexts && (
        <BannerOne
          {...bannerOne}
          boughtPaperback={userHasBoughtPaperbackBook}
        />
      )}

      <PaymentStepsContainer>
        <PaymentStepsStyled
          {...paymentSteps}
          hasOptionalStep={userHasBoughtPaperbackBook}
        />
      </PaymentStepsContainer>
      <TopTextContainer>
        <HeadlineAlt>{headlineAlt}</HeadlineAlt>
        <LabelAlt dangerouslySetInnerHTML={{ __html: labelAlt }} />
      </TopTextContainer>
      <PlanSectionComponent {...planSection} />
      {list && (
        <ListContainer>
          <ListWrapper>
            <ListStyled {...list} />
          </ListWrapper>
        </ListContainer>
      )}

      <Headline {...headline2} />
      <Headline {...headline3} />
      <Label {...label} />
      <PlanSectionComponent {...planSection2} />
      <ImgContainer>
        {commentsTitle && (
          <CommentsTitleContainer>
            <CommentsTitleWrapper>
              <StyledLikeIcon />
              <CommentsTitle>{commentsTitle}</CommentsTitle>
            </CommentsTitleWrapper>
            {commentsSubtitle && (
              <CommentsSubtitle>{commentsSubtitle}</CommentsSubtitle>
            )}
          </CommentsTitleContainer>
        )}
        <ImageSectionStyled
          src={isTablet ? imageSection.imageMobile : imageSection.image}
        />
      </ImgContainer>
      {disclaimer && <UpgradeDisclaimer {...disclaimer} />}
    </>
  );
};

export default AppSubscription;
