import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Question, QuizAnswer } from 'types/quiz';
import { DynamicImage, Text } from 'components';
import { desktop, mMobile, tablet, useQuery } from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import LCFooter from 'components/LCFooter';
import socProof from 'assets/images/landing/animated_image_mob.png';
import socProof_des from 'assets/images/landing/animated_image_des.png';
import bfSocProof from '../../../../assets/images/lastingChange/bf-soc-prof-mob.png';
import bfSocProof_des from '../../../../assets/images/lastingChange/bf-soc-prof-des.png';
import xmasSocProof from '../../../../assets/images/landing/xmas-soc-prof-mob.png';
import xmasSocProof_des from '../../../../assets/images/landing/xmas-soc-prof-des.png';
import winterSocProof from '../../../../assets/images/landing/winter-soc-prof-mob.png';
import winterSocProof_des from '../../../../assets/images/landing/winter-soc-prof-des.png';
import nySocProof from '../../../../assets/images/landing/ny-soc-prof-mob.png';
import nySocProof_des from '../../../../assets/images/landing/ny-soc-prof-des.png';
import valentineSocProof from '../../../../assets/images/lastingChange/valentine-soc-prof-mob.png';
import valentineSocProof_des from '../../../../assets/images/lastingChange/valentine-soc-prof-des.png';
import Logo from 'assets/icons/logo/logoWithName.svg';
import Triangle from 'assets/icons/triangle.svg';
import WhitePercent from 'assets/icons/white-percent.svg';
import BlackFridayBanner from 'components/BlackFridayBanner';
import { useFirstVisitDate, useScrollToTop } from 'utils/hooks';
import BlackFridayLandingLabel from './BlackFridayLandingLabel';
import Tracking from 'utils/tracking';
import { getCookiesEmail, removeCookiesEmail } from 'utils/cookies';
import RModal, { Props, Styles } from 'react-modal';
import ContinueButton from './ModalButton';
import { useDispatch } from 'react-redux';
import { fetchVisitedUser } from 'state/user/effects';
import { navigate } from 'gatsby';
import { useRouter } from 'apis/history';
import Cookies from 'js-cookie';
import PRFooter from 'components/PRFooter';
import Header from 'components/reviews/Header';
import LoopingBanner from 'components/LoopingBanner';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
}

interface ButtonsContainerProps {
  length: number;
  hasBanner: boolean;
}

const customStyle: Styles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.60)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    borderRadius: '0.5rem',
    maxWidth: '30rem',
    position: 'unset',
    margin: '0 1rem',
  },
};

const Landing: FC<LandingProps> = ({ question, onOptionSelect }) => {
  const { isTablet } = useQuery();
  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-dog';
  const isAddsFlow = location.pathname.includes('start') || quiz !== 'main-dog';
  const { countdownDiffInMS } = useFirstVisitDate();
  const bfBannerExists = question?.bfBanner;
  const [retargetingEmail, setRetargetingEmail] = useState<string | null>(null);
  const [retargetingModalOpen, setRetargetingModalOpen] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const { goToResults } = useRouter();

  useEffect(() => {
    const userEmail = getCookiesEmail();

    if (userEmail?.email) {
      setRetargetingEmail(userEmail?.email);
      setRetargetingModalOpen(true);
      Tracking.logEvent({
        event: `popup-visible`,
      });
    }
  }, []);

  const handleContinue = async () => {
    await dispatch(fetchVisitedUser());

    Cookies.set('modalUsed', 'true', {
      expires: 7,
      secure: true,
      sameSite: 'strict',
    });

    goToResults();

    Tracking.logEvent({
      event: `popup-accept`,
    });
  };

  const handleRestart = () => {
    removeCookiesEmail();
    setRetargetingModalOpen(false);
    Tracking.logEvent({
      event: `popup-reject`,
    });
  };

  const pickSocialProfImage = (variant?: string) => {
    if (variant === 'blackFriday') {
      return {
        mob: bfSocProof,
        des: bfSocProof_des,
      };
    }
    if (variant === 'xmas') {
      return {
        mob: xmasSocProof,
        des: xmasSocProof_des,
      };
    }
    if (variant === 'ny') {
      return {
        mob: nySocProof,
        des: nySocProof_des,
      };
    }
    if (variant === 'valentine') {
      return {
        mob: valentineSocProof,
        des: valentineSocProof_des,
      };
    }
    if (variant === 'winter') {
      return {
        mob: winterSocProof,
        des: winterSocProof_des,
      };
    }
    return {
      mob: socProof,
      des: socProof_des,
    };
  };

  const handleOptionsSelect = (item: any) => {
    const logEvent = (data: { category: string; label?: string }) => {
      Tracking.logEvent({
        event: `Quiz question answered - 1`,
        category: `1 - ${data.category}`,
        label: data.label,
      });
    };

    logEvent({
      category: `${question.label}${
        question.optionsLabel ? ` - ${question.optionsLabel}` : ''
      }`,
      label: String(item?.label),
    });
    onOptionSelect({
      [question?.key]: item?.gender,
      label: item?.label,
    });
  };

  const renderGenderButtons = (options: any[]) => (
    <>
      {options?.map((option, index) => (
        <GenderButton
          key={index}
          color={option?.color}
          colorActive={option?.color}
          onClick={() =>
            handleOptionsSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          marginRight={index === 0}
          isSingleButton={question?.options?.length === 1}
        >
          {option?.label}
        </GenderButton>
      ))}
    </>
  );

  const renderGenderBasic = (options: any[]) => {
    if (!options.length) {
      return null;
    }
    return options?.map((option, index) => {
      const hasColor = !!option.color;
      return (
        <OtherGenderButton
          onClick={() =>
            handleOptionsSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          color={option?.color}
          colorActive={option?.color}
          key={index}
        >
          {hasColor ? (
            option?.label
          ) : (
            <Text type="body500">{option?.label}</Text>
          )}
        </OtherGenderButton>
      );
    });
  };

  const renderGenderOptions = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainer
        length={[...primaryOptions, ...secondaryOptions].length}
        hasBanner={!!question?.bannerTexts}
      >
        {renderGenderButtons(primaryOptions)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainer>
    );
  };

  useScrollToTop(0);

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <>
      <Container>
        {bfBannerExists ? (
          <BlackFridayBannerStyled
            {...question.bfBanner}
            countdownDiffInMS={countdownDiffInMS}
          />
        ) : null}
        {!isAddsFlow && (
          <HeaderWrapper>
            <Header isMobileApp={false} />
            {question?.bannerTexts && (
              <LoopingBanner
                bannerTexts={question?.bannerTexts.texts}
                primaryTextColor={question?.bannerTexts.primaryColor}
                secondaryTextColor={question?.bannerTexts.secondaryColor}
                primaryGradient={question?.bannerTexts.primaryGradient}
                secondaryGradient={question?.bannerTexts.secondaryGradient}
                backgroundColor={question?.bannerTexts.backgroundColor}
                isNotSticky={true}
              />
            )}
          </HeaderWrapper>
        )}
        {isAddsFlow && !isTablet && (
          <HeaderWrapper>
            {question?.bannerTexts && (
              <LoopingBanner
                bannerTexts={question?.bannerTexts.texts}
                primaryTextColor={question?.bannerTexts.primaryColor}
                secondaryTextColor={question?.bannerTexts.secondaryColor}
                primaryGradient={question?.bannerTexts.primaryGradient}
                secondaryGradient={question?.bannerTexts.secondaryGradient}
                backgroundColor={question?.bannerTexts.backgroundColor}
                isNotSticky={true}
              />
            )}
          </HeaderWrapper>
        )}
        {/* {!isAddsFlow && <Header isMobileApp={false} />} */}
        <RModal isOpen={retargetingModalOpen} style={customStyle}>
          <ModalTitle>
            You&apos;ve already completed the Raising Dog quiz before
          </ModalTitle>
          <ModalSubtitle>
            You can <span>Continue</span> where you paused or{' '}
            <span>Restart Quiz</span> to personalize your book from scratch.
          </ModalSubtitle>
          <ModalSubtitle>
            Continue as <span>{getCookiesEmail().email}</span>
          </ModalSubtitle>
          <ButtonContainer>
            <ContinueButton
              fontSize={1.25}
              color="newGreen"
              cornerVariant="round"
              fontWeight={500}
              onClick={() => handleContinue()}
            >
              Continue
            </ContinueButton>
            <ContinueButton
              fontWeight={500}
              color="light0"
              fontColor="#38724F"
              borderColor="newGreen"
              title="Restart Quiz"
              cornerVariant="round"
              fontSize={1.25}
              onClick={() => handleRestart()}
            />
          </ButtonContainer>
        </RModal>
        <Wrapper isFullHeight={isAddsFlow}>
          {isAddsFlow && (
            <>
              {!!question?.bannerTexts && isTablet ? (
                <HeaderWrapper>
                  <HeaderContainer>
                    <HeaderImg
                      src="assets/icons/logo/logoWithName.svg"
                      alt=""
                    />
                  </HeaderContainer>
                  {question?.bannerTexts && (
                    <LoopingBanner
                      bannerTexts={question?.bannerTexts.texts}
                      primaryTextColor={question?.bannerTexts.primaryColor}
                      secondaryTextColor={question?.bannerTexts.secondaryColor}
                      primaryGradient={question?.bannerTexts.primaryGradient}
                      secondaryGradient={
                        question?.bannerTexts.secondaryGradient
                      }
                      backgroundColor={question?.bannerTexts.backgroundColor}
                      isNotSticky={true}
                    />
                  )}
                </HeaderWrapper>
              ) : (
                <HeaderContainer>
                  <HeaderImg src="assets/icons/logo/logoWithName.svg" alt="" />
                </HeaderContainer>
              )}
            </>
          )}

          <W1>
            <SocialProof
              mob={pickSocialProfImage(question?.socialProofVariant).mob}
              des={pickSocialProfImage(question?.socialProofVariant).des}
              isFullHeight={isAddsFlow}
              isBanner={!!question?.bannerTexts}
              isOrganic={!isAddsFlow}
            />
          </W1>

          <WrappedItem>
            <W3>
              <TitleContainer>
                <Title
                  type={isTablet ? 'h2' : 'h1XL'}
                  textAlign={isTablet ? 'center' : 'left'}
                  dangerouslySetInnerHTML={{ __html: question?.title || '' }}
                />
              </TitleContainer>

              <DescriptionContainer>
                <Label
                  type={isTablet ? 'body' : 'h3400'}
                  color="dark80"
                  textAlign={isTablet ? 'center' : 'left'}
                  dangerouslySetInnerHTML={{
                    __html: question?.description || '',
                  }}
                  descriptionIsMaxWidth={question?.descriptionIsMaxWidth}
                />
              </DescriptionContainer>

              <ButtonsTitleContainer>
                <Label
                  dangerouslySetInnerHTML={{ __html: question?.label }}
                  textAlign={isTablet ? 'center' : 'left'}
                  type={isTablet ? 'bodyS500' : 'body500'}
                />
              </ButtonsTitleContainer>
              <ButtonsMainConatiner>
                {renderGenderOptions()}
                {question?.bannerButtonText && (
                  <ButtonsBanner bgColor={question?.bannerButtonBgColor}>
                    <WhitePercent />
                    <LeftTriangle fillColor={question?.bannerButtonBgColor} />
                    <RightTriangle fillColor={question?.bannerButtonBgColor} />
                    <ButtonBannerText
                      spanColor={question?.bannerButtonSpanColor}
                      dangerouslySetInnerHTML={{
                        __html: question?.bannerButtonText,
                      }}
                    />
                  </ButtonsBanner>
                )}
              </ButtonsMainConatiner>
              {question?.bfLabel ? (
                <BlackFridayLandingLabel {...question.bfLabel} />
              ) : null}
            </W3>
          </WrappedItem>
        </Wrapper>
        {!isAddsFlow ? (
          <LCFooter disclaimerText={question?.disclaimerParagraphs || []} />
        ) : (
          <PRFooter disclaimerText={question?.disclaimerParagraphsPR || []} />
        )}
      </Container>
    </>
  );
};

export default Landing;

const ButtonBannerText = styled.p<{ spanColor?: string }>`
  color: #fff;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.03rem;
  font-family: 'Open Sans', sans-serif;

  b {
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
  }

  span {
    font-weight: 700;
    color: ${({ spanColor }) => spanColor ?? '#E6B241'};
  }

  @media ${tablet} {
    font-size: 1rem;
    letter-spacing: -0.024rem;
  }
`;

const ButtonsBanner = styled.p<{ bgColor?: string }>`
  position: relative;
  display: flex;
  max-width: 38.8125rem;
  width: 100%;
  padding: 0.375rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  background: ${({ bgColor }) => (bgColor ? bgColor : '#a4202f')};
  color: #fef3f3;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin-top: 0.875rem;
  gap: 0.5rem;
  height: 3.5rem;
  @media ${tablet} {
    max-width: unset;
    height: 3rem;
  }
`;

const LeftTriangle = styled(Triangle)<{ fillColor?: string }>`
  position: absolute;
  top: -0.375rem;
  left: 20%;

  path {
    fill: ${({ fillColor }) => (fillColor ? fillColor : '#A4202F')};
  }
`;

const RightTriangle = styled(Triangle)<{ fillColor?: string }>`
  position: absolute;
  top: -0.375rem;
  right: 20%;

  path {
    fill: ${({ fillColor }) => (fillColor ? fillColor : '#A4202F')};
  }
`;

const ButtonsMainConatiner = styled.div`
  max-width: 38.8125rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${tablet} {
    max-width: unset;
  }
`;

const ButtonContainer = styled.div`
  flex-direction: column;
  display: flex;
  gap: 1rem;
  margin-top: 1.25rem;
`;

const ModalSubtitle = styled(Text)`
  color: #000;
  margin-top: 1rem;
  font-family: 'Open Sans';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4375rem;
  span {
    font-weight: 700;
  }
`;

const ModalTitle = styled(Text)`
  color: #000;
  font-family: 'Open Sans';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const BlackFridayBannerStyled = styled(BlackFridayBanner)``;

const W3 = styled.div`
  max-width: 720px;
  width: 100%;
  @media ${tablet} {
    margin: auto;
  }
`;

const W1 = styled.div`
  display: flex;
  flex: 1;
  max-width: 50%;

  @media ${tablet} {
    max-width: 100%;
    flex: 0;
    padding: 0;
  }
`;

const HeaderWrapper = styled.section`
  position: sticky;
  top: 0;
  z-index: 10;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.light0};
`;

const WrappedItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: inherit;
  margin-left: auto;
  padding-top: 72px;
  @media ${tablet} {
    padding: 1rem 1rem 0;
    width: unset;
    margin-left: unset;
    flex: 0;
  }
`;

const Wrapper = styled.div<{ isFullHeight?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex: 1;
  gap: 3rem;
  max-width: 90rem;
  width: 100%;
  height: 100vh;
  padding: 0 3rem;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.light0};
  ${({ isFullHeight }) => isFullHeight && 'min-height: 100vh;'}
  @media ${tablet} {
    padding: 0;
    flex-direction: column;
    align-items: unset;
    gap: 0.5rem;
  }
`;

const HeaderImg = styled(Logo)`
  height: 32px;
  width: 146px;
  @media ${tablet} {
    height: 24px;
    width: 110px;
  }
`;

const HeaderContainer = styled.div<{ top?: string }>`
  position: absolute;
  top: ${({ top = '0' }) => top};
  display: flex;
  align-items: center;
  height: 72px;
  left: 3rem;
  margin: 0 auto;
  width: 100%;
  background: ${({ theme }) => theme.colors.light0};

  @media ${tablet} {
    position: static;
    top: auto;
    height: 68px;
    align-items: center;
    justify-content: center;
    padding-left: unset;
    width: unset;
  }
`;

const slideV = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 10000%;
  }
`;

const slideH = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000% 0;

  }
`;

// const SocialProof = styled.div`
//   position: relative;
//   background-size: cover;
//   animation: ${slideV} 1500s linear infinite;
//   width: 100%;
//   background-image: url(${socProof_des});
//   height: 80vh;

//   @media ${tablet} {
//     background-image: url(${socProof});
//     animation: ${slideH} 1500s linear infinite;
//     height: 300px;
//   }
// `;

const SocialProof = styled.div<{
  des: string;
  mob: string;
  isFullHeight?: boolean;
  isBanner?: boolean;
  isOrganic?: boolean;
}>`
  position: relative;
  background-size: cover;
  animation: ${slideV} 1500s linear infinite;
  width: 100%;
  background-image: url(${({ des }) => des});
  height: ${({ isFullHeight }) => (isFullHeight ? '100vh' : '85vh')};
  @media ${tablet} {
    animation: ${slideH} 1500s linear infinite;
    background-image: url(${({ mob }) => mob});
    height: 308px;
    ${({ isOrganic }) => isOrganic && 'margin-top: 0.81rem;'}
  }
  @media ${mMobile} {
    ${({ isBanner }) => isBanner && 'height: 180px;'}
  }
`;

const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  gap: 1rem;
  @media ${tablet} {
    ${({ hasBanner }) => !hasBanner && 'padding-bottom: 32px;'}
    justify-content: center;
  }
`;

const Title = styled(Text)`
  @media ${tablet} {
    text-align: center;
  }
`;

const Label = styled(Text)<{
  descriptionIsMaxWidth?: boolean;
  fontSizing?: number;
}>`
  * {
    font-size: inherit;
  }
  @media ${tablet} {
    width: ${({ descriptionIsMaxWidth }) =>
      descriptionIsMaxWidth ? '100%' : '21.438rem'};
  }
`;

const DescriptionContainer = styled.div`
  padding-bottom: 3rem;
  max-width: 624px;
  @media ${tablet} {
    text-align: center;
    text-align-last: center;
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: center;
  }
`;

const TitleContainer = styled.div`
  padding-bottom: 1.5rem;
  justify-content: center;
  display: flex;
  width: 100%;

  @media ${tablet} {
    text-align: center;
    padding-bottom: 1rem;
  }
`;

const GenderButton = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})<{ marginRight?: boolean; isSingleButton?: boolean }>`
  padding: 0 1rem;
  width: 100%;
  height: 3.5rem;
  /* max-width: 15rem; */
  /* margin-right: 1rem; */
  /* flex-basis: ${({ isSingleButton }) =>
    isSingleButton ? '100%' : 'calc((100% / 3) - 1rem)'}; */
  @media ${tablet} {
    height: 3rem;
    /* max-width: 30rem; */
    /* flex-basis: ${({ isSingleButton }) =>
      isSingleButton ? '100%' : 'calc(50% - 1rem)'};
    margin-right: ${({ marginRight }) => (marginRight ? 1 : 0)}rem; */
  }
`;

const OtherGenderButton = styled(GenderButton)`
  flex-basis: calc(33% - 1rem);
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color] : 'transparent'};

  @media ${tablet} {
    flex-basis: calc(100% - 1rem);
    margin-top: 0.5rem;
  }
`;

const ButtonsTitleContainer = styled.div`
  padding-bottom: 1.5rem;

  @media ${tablet} {
    text-align: center;
    padding-bottom: 0.75rem;
    display: flex;
    justify-content: center;
  }
`;
